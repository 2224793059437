<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" md="6">
        <v-breadcrumbs
            :items="breadcumb"
            divider="-"
            style="color: #d81d86;margin-left: 5em"/>
      </v-col>
      <v-col v-if="travel.typus == 1 || travel.typus == 2" cols="12" md="6" class="align-content-end align-end" style="text-align: center;margin-top: 1em;">
        <router-link to='/' style="color: #d81d86;margin-left: 5em; font-size: 11pt" v-text="$ml.get('travel.link')" />
      </v-col>
    </v-row>
  </v-col>

</template>

<script>
export default {
  name: "breadcumbs_section",
  props:['breadcumb','travel']
}
</script>

<style scoped>

</style>