<template>
  <v-card-title class="titleSection">
    <span class="stepIndicator" v-if="step <= stepEqual">
      {{ indicator }}
    </span>
    <span class="stepIndicator" v-else>
      <v-icon color="white">mdi-check</v-icon>
    </span>
    {{ title }}
  </v-card-title>
</template>

<script>
export default {
  name: "titleSection",
  props: ['title', 'step', 'stepEqual', 'indicator']
}
</script>

<style scoped>
.titleSection {
  color: #d81d86;
}

.stepIndicator {
  background: #d81d86;
  width: 31px;
  border-radius: 15px;
  margin-right: 0.5em;
  color: white;
  text-align:center;
}
</style>