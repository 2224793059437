<template>
  <v-card :height="cardHeight()">
    <v-card-title class="text-center justify-center">
      <h1 class="titleTravel text-center" v-if="$ml.current === 'Esp'">{{ travel.titleES }}</h1>
      <h1 class="titleTravel text-center" v-else>{{ travel.titleCAT }}</h1>
    </v-card-title>
    <v-card-text align="center">
      <v-row justify="center" align="center" class="ma-auto">
        <v-col cols="12" md="6">
          <div class="subtitle-1" v-bind:class="{'my-4' : !isMobile}">
            <i class="far fa-calendar-alt"/> <b v-text="$ml.get('travel.head.date')"/><br> {{ date }}
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="subtitle-1" v-bind:class="{'my-4' : !isMobile}" v-if=" travel.duration==='1'">
            <i class="far fa-clock"/> <b v-text="$ml.get('travel.head.duration')"/><br> {{ travel.duration }} <span
              v-text="$ml.get('travel.head.day')"/>
          </div>
          <div class="subtitle-1" v-bind:class="{'my-4' : !isMobile}" v-else>
            <i class="far fa-clock"/> <b v-text="$ml.get('travel.head.duration')"/><br> {{ travel.duration }} <span
              v-text="$ml.get('travel.head.days')"/>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class=" subtitle-1" v-bind:class="{'my-4' : !isMobile}">
            <i class="fas fa-wallet"/> <b v-text="$ml.get('travel.head.price')"/><br> {{ travel.price }}€<br>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class=" subtitle-1" v-bind:class="{'my-4' : !isMobile}" v-if="quantity>0">
            <v-btn class="mt-2 ml-3" v-if="quantity>0" dark color="#d81d86" v-scroll-to="'#reservation'">Reservar
            </v-btn>
          </div>
          <div class=" subtitle-1" v-bind:class="{'my-4' : !isMobile}" v-else>
            <low-stock-alert v-if="quantity<=10 && quantity>0"/>
            <out-of-stock-alert v-if="quantity<=0"/>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LowStockAlert from "../../partials/lowStockAlert";
import OutOfStockAlert from "../../partials/outOfStockAlert";

export default {
  name: "headInfo",
  data() {
    return {
      date: ''
    }
  },
  components: {OutOfStockAlert, LowStockAlert},
  props: ['travel', 'isMobile', 'quantity'],
  mounted() {
    const splitDate = this.travel.date.split('-');
    this.date = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
  },
  methods: {
    cardHeight() {
      if (this.isMobile) return 420
      else return 360
    }
  }
}
</script>

<style scoped>
.titleTravel {
  font-size: 24pt;
  font-weight: bold;
  color: #d81d86;
  word-break: keep-all;
}

.suplemento {
  font-size: 11pt;
  color: gray;
}

.subtitle-1 {
  font-size: 1.25rem !important;
}
</style>