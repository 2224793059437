<template>
  <form ref="redsys_form" action="https://sis.redsys.es/sis/realizarPago" method="post"
        id="redsys_form" name="redsys_form">
    <input type="text" name="Ds_SignatureVersion" v-model="Ds_SignatureVersion" hidden style="display: none"/>
    <input type="text" name="Ds_MerchantParameters" v-model="Ds_MerchantParameters" hidden
           style="display: none"/>
    <input type="text" name="Ds_Signature" v-model="Ds_Signature" hidden style="display: none"/>
    <v-btn
        align="start"
        class="ma-2 justify-start payBT"
        :loading="loading"
        :disabled="!availableButton()"
        color="#d81d86"
        dark
        @click="submit">
      Pagar
      <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
      </template>
    </v-btn>
  </form>
</template>

<script>
export default {
  name: "payBusButton",
  props: ['passengers', 'total', 'availableButton', 'travel', 'selection', 'refresh'],
  data() {
    return {
      loading: false,
      paymentData: {
        DS_MERCHANT_AMOUNT: 50,
        DS_MERCHANT_CURRENCY: "978",
        DS_MERCHANT_MERCHANTCODE: "337481998",
        DS_MERCHANT_MERCHANTURL: "",
        DS_MERCHANT_ORDER: '',
        DS_MERCHANT_TERMINAL: "3",
        DS_MERCHANT_TRANSACTIONTYPE: "0",
        DS_MERCHANT_URLKO: this.env_url + '/viaje/' + this.$route.params.ref,
        DS_MERCHANT_URLOK: ''
      },
      Ds_MerchantParameters: null,
      Ds_Signature: null,
      Ds_SignatureVersion: null,
    }
  },
  methods: {
    submit() {
      this.loading = true;
      const cart = this.passengers[0].nif;
      const key = Date.now().toString();
      this.paymentData.DS_MERCHANT_ORDER = this.CryptoJS.AES.encrypt(cart, key).toString().substr(33, 10);
      let formData = new FormData();
      formData.set('ref', this.$route.params.ref);
      formData.set('name', this.passengers[0].name);
      formData.set('nif', this.passengers[0].nif)
      formData.set('email', this.travel.email)
      formData.set('passengers', JSON.stringify(this.passengers));
      formData.set('seats', JSON.stringify(this.selection));
      formData.set('redsis', this.paymentData.DS_MERCHANT_ORDER)
      formData.set('total', this.total)
      let url = this.base_url + 'client/buserva'
      this.axios.post(url, formData).then(response => {
        this.$ma.identify({userId: response.data.reservationID, options: {newUser: true}});
        this.$ma.setAlias(this.travel.email);
        this.$ma.setUsername(this.passengers[0].name);
        this.$ma.setUserProperties({
          userId: response.data.reservationID,
          name: this.passengers[0].name,
          redsiscode: this.paymentData.DS_MERCHANT_ORDER
        })
        this.loading = false;
        this.paymentData.DS_MERCHANT_AMOUNT = this.total;
        if (this.$ml.current === 'Esp')
          this.paymentData.DS_MERCHANT_MERCHANTURL = "https://kariba.autocarsgamon.com/API/client/TPVNotification.php?lang=1";
        else
          this.paymentData.DS_MERCHANT_MERCHANTURL = "https://kariba.autocarsgamon.com/API/client/TPVNotification.php?lang=2";
        this.paymentData.DS_MERCHANT_URLOK = this.env_url + '/confirmacion/' + this.$route.params.ref + '/' + response.data.reservationID;
        this.paymentData.DS_MERCHANT_URLKO = this.env_url + '/errorenpago/' + this.$route.params.ref + '/' + response.data.reservationID;
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'Reserva OK',
          properties: {
            travel: this.$route.params.ref,
            rid: response.data.reservationID,
            merchanturl: this.paymentData.DS_MERCHANT_MERCHANTURL,
            merchantOK: this.paymentData.DS_MERCHANT_URLOK,
            merchantKO: this.paymentData.DS_MERCHANT_URLKO,
            version: this.version
          }
        });
        this.checkPayment()
      }).catch(err => {
        this.loading = false
        if (err.response.status === 403) {
          this.$ma.trackEvent({
            category: 'Travel',
            action: 'Busy seats',
            properties: {travel: this.$route.params.ref, version: this.version}
          });
          this.refresh();
        }
      });
    },
    async checkPayment() {
      this.$ma.trackEvent({
        category: 'paymenterror',
        action: 'go to TPV',
        properties: {travel: this.$route.params.ref, version: this.version}
      });
      let encodeData = await this.encodePayment()
      if (encodeData) this.$refs.redsys_form.submit()
    },

    async encodePayment() {
      try {
        this.priceForRedsys()
        let merchant = this.$CryptoJS.enc.Utf8.parse(JSON.stringify(this.paymentData))
        this.Ds_MerchantParameters = merchant.toString(this.$CryptoJS.enc.Base64)
        let key = this.$CryptoJS.enc.Base64.parse('k5t/2miwt2/WbHLB4kwT0zUtMDG+smzJ')
        let iv = this.$CryptoJS.enc.Hex.parse("0000000000000000");
        let cipher = this.$CryptoJS.TripleDES.encrypt(this.paymentData.DS_MERCHANT_ORDER, key, {
          iv: iv,
          mode: this.$CryptoJS.mode.CBC,
          padding: this.$CryptoJS.pad.ZeroPadding // Redsys dont like padding
        })
        let signature = this.$CryptoJS.HmacSHA256(this.Ds_MerchantParameters, cipher.ciphertext);
        this.Ds_Signature = signature.toString(this.$CryptoJS.enc.Base64)
        this.Ds_SignatureVersion = "HMAC_SHA256_V1"
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    priceForRedsys() {
      let priceN = parseFloat(this.total)
      if (Number.isInteger(priceN)) {
        this.paymentData.DS_MERCHANT_AMOUNT = priceN.toString() + '00'
      } else {
        let price_fixed = priceN.toFixed(2).toString()
        let price_string = price_fixed.replace('.', '')
        this.paymentData.DS_MERCHANT_AMOUNT = price_string
      }
    }
  }
}
</script>

<style scoped>

</style>