<template>
  <v-col cols="12" md="10" align="center" v-if="loaded">
    <travel-info :desc="travel.descriptionES" v-if="loaded && $ml.current === 'Esp'"/>
    <travel-info :desc="travel.descriptionCAT" v-if="loaded && $ml.current === 'Cat'"/>

    <pdf-terms :files="files"/>
    <v-col cols="12" md="isIpad()" class="infoTravel" id="reservation">
      <v-alert
          border="bottom"
          color="pink darken-1"
          dark
          v-if="msg">
       Lo sentimos, uno o más asientos de su selección ya han sido reservados por otro usuario</v-alert>
      <bus-form-comp v-if="(travel.typus==='1' || travel.typus==='4') && this.quantity>0" :travel="travel" :seats="seats" :quantity="quantity" :termsname="files[0].name" :is-mobile="isMobile" :refresh="refresh"/>
      <hotel-form-comp v-else-if="(travel.typus==='2' || travel.typus==='3')  && this.quantity>0" :travel="travel" :seats="seats" :quantity="quantity"  :termsname="files[0].name" :is-mobile="isMobile" :refresh="refresh"/>
    </v-col>
  </v-col>
</template>

<script>


import TravelInfo from "./partials/travelInfo";
import PdfTerms from "./partials/pdfTerms";
import BusFormComp from "./partials/busFormComp";
import HotelFormComp from "./partials/hotelFormComp";
export default {
  name: "travel_section",
  components: {HotelFormComp, BusFormComp, PdfTerms, TravelInfo},
  props:['travel','loaded','files','seats','quantity','refresh','msg'],
  data(){
    return{
      screenWidth:null,
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth
  },
  methods:{
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return this.screenWidth<=768;
      }
    },
    isIpad(){
      if(window.innerWidth>1024) return 10
      else return 11;
    }
  }
}
</script>

<style scoped>

</style>