<template>
  <section>
    <title-section :title="$ml.get('travel.form.persons')" :step="step" :step-equal="0" :indicator="1"/>
    <v-card-text>
      <v-col cols="12" md="3">
        <v-select
            :items="items"
            color="#000000"
            v-model="selected"
            :menu-props="{ top: true, offsetY: true }"
            :label="$ml.get('travel.form.personsSelect')"/>
      </v-col>
    </v-card-text>
  </section>
</template>

<script>
import TitleSection from "./titleSection";
export default {
  name: "personsQuery",
  components: {TitleSection},
  props:['items','step'],
  data() {
    return {
      selected:null
    }
  },
  watch:{
    selected: function (qty){
      this.$emit('selected',qty)
    }
  }
}
</script>

<style scoped>

</style>