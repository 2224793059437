<template>
  <v-alert
      dense
      outlined
      color="gray"
      type="error"
      class="ma-2">
    Plazas Agotadas
  </v-alert>
</template>

<script>
export default {
  name: "outOfStockAlert"
}
</script>

<style scoped>

</style>