<template>
  <v-card-text>
    <v-col cols="12" class="roomCol">
      <v-col cols="12" sm="12" md="2" v-if="travel.singleRoom === '1'">
        <label style="word-wrap: anywhere">
          <b class="roomTitle" v-text="$ml.get('travel.form.rooms.indiv')"/>
          <span class="roomSubtitle" v-if="travel.singleRoomPlus!=='0'"> ({{
              $ml.get('travel.form.rooms.sup')
            }} {{ travel.singleRoomPlus }}€)</span></label>
        <v-select
            :items="indivAvailableRooms"
            v-model="indiv"
            :menu-props="{ top: true, offsetY: true }"/>
      </v-col>
      <v-col cols="12" sm="12" md="2" v-if="travel.doubleRoom === '1'">
        <label>
          <b class="roomTitle" v-text="$ml.get('travel.form.rooms.double')"/>
          <span class="roomSubtitle" v-if="travel.doubleRoomPlus!=='0'"> ({{
              $ml.get('travel.form.rooms.sup')
            }} {{ travel.doubleRoomPlus }}€)</span></label>
        <v-select
            :items="dobleAvailableRooms"
            v-model="doble"
            :menu-props="{ top: true, offsetY: true }"/>
      </v-col>
      <v-col cols="12" sm="12" md="2" v-if="travel.tripleRoom === '1'">
        <label>
          <b class="roomTitle" v-text="$ml.get('travel.form.rooms.triple')"/>
          <span class="roomSubtitle" v-if="travel.tripleRoomPlus!=='0'"> ({{
              $ml.get('travel.form.rooms.sup')
            }} {{ travel.tripleRoomPlus }}€)</span></label>
        <v-select
            :items="tripleAvailableRooms"
            v-model="triple"
            :menu-props="{ top: true, offsetY: true }"/>
      </v-col>
    </v-col>
  </v-card-text>
</template>

<script>
export default {
  name: "roomForm",
  data(){
    return{
      indiv:0,
      doble:0,
      triple:0
    }
  },
  props:['travel','indivAvailableRooms','dobleAvailableRooms','tripleAvailableRooms'],
  watch: {
    indiv: function (qty) {
      this.$emit('recalculate',qty, 1)
    },
    doble: function (qty) {
      this.$emit('recalculate',qty, 2)
    },
    triple: function (qty) {
      this.$emit('recalculate',qty, 3)
    },
  },

}
</script>

<style scoped>

</style>