<template>
  <v-alert
      dense
      outlined
      color="#b52787"
      type="error"
      class="ma-2">
    Últimas Plazas Disponibles
  </v-alert>
</template>

<script>
export default {
  name: "lowStockAlert"
}
</script>

<style scoped>

</style>