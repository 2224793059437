<template>
  <v-row align="center" justify="center">
    <svg-top-gradient/>
    <breadcumbs_section :travel="travel" :breadcumb="breadcumb"/>
    <header_section :travel="travel" :is-mobile="isMobile" :loaded="loaded" :quantity="quantity"/>
    <travel_section v-if="dataLoaded" :travel="travel" :files="files" :seats="seats" :quantity="quantity"
                    :loaded="loaded" :refresh="busySeat" :msg="busyMsg"/>
  </v-row>
</template>

<script>
import SvgTopGradient from "../components/partials/svgTopGradient";
import breadcumbs_section from "../components/travel/breadcumbs_section";
import header_section from "../components/travel/header_section";
import travel_section from "../components/travel/travel_section";

export default {
  name: "Travel",
  components: {SvgTopGradient, breadcumbs_section, header_section, travel_section},
  data: () => ({
    busyMsg: false,
    travel: [],
    seats: [],
    files: [],
    quantity: 0,
    dataLoaded: true,
    loaded: false,
    breadcumb: null,
    title: 'Kariba Viatges',
    img: ''
  }),
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: this.title,
      meta: [
        {property: 'og:image', content: this.img},
        {property: 'twitter:image:src', content: this.img},
        {property: 'og:title', content: this.title},
        {property: 'og:site_name', content: this.title},
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  },
  mounted() {
    this.getData()
    this.refreshUnconfirmedReservations()
    if (this.$ml.current === 'Esp') {
      this.breadcumb = [
        {
          text: 'Viajes',
          disabled: false,
          href: '/',
        },
        {
          text: '',
          disabled: true,
          href: null,
        }
      ];
    } else {
      this.breadcumb = [
        {
          text: 'Viatges',
          disabled: false,
          href: '/',
        },
        {
          text: '',
          disabled: true,
          href: null,
        }
      ];
    }
  },
  methods: {
    busySeat() {
      this.busyMsg = true;
      this.getData();
    },
    refreshUnconfirmedReservations() {
      let url = this.base_url + "/admin/borrarpendientes";
      this.axios.get(url).then(() => {
      })
    },
    getData() {
      this.dataLoaded = false;
      let url = this.base_url + "/client/viaje/" + this.$route.params.ref
      console.log(url)
      this.axios.get(url).then(response => {
        this.travel = response.data.travel;
        this.seats = response.data.seats;
        this.files = response.data.files;
        this.resqty = this.seats.length;
        this.quantity = (parseInt(this.travel.places) - this.resqty);
        this.breadcumb[1].text = this.travel.ref
        this.loaded = true;
        this.dataLoaded = true;
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'get travel',
          properties: {version: this.version, reference: this.$route.params.ref}
        });
        this.title = this.travel.titleCAT + ' | Kariba Viatges';
        this.img = this.image_url + this.travel.image
      }).catch(err => {
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'failed get travel',
          properties: {errorMessage: err.response.data, errorStatus: err.response.status, version: this.version}
        });
      });
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style>

.v-breadcrumbs li > a {
  color: #d81d86;
}
</style>
