<template>
  <section style="background: #fcfcfc">
    <title-section :title="$ml.get('travel.form.personalData')" :step="step" :step-equal="2" :indicator="3"/>
    <v-card-text style="display: table">
      <v-col cols="12" md="4" class="justify-start" v-bind:class="{'ml-4':!isMobile}">
        <v-text-field
            :autofocus="true"
            @change="checkPassengers"
            @keypress="checkPassengers"
            @keydown="checkPassengers"
            color="rgb(181, 39, 135)"
            :label="$ml.get('travel.form.contactMail')" v-model="travel.email" :rules="emailRules"/>
      </v-col>
      <v-col cols="12" md="4" class="justify-start" v-if="travel.typus==='4' && 1==2" v-bind:class="{'ml-4':!isMobile}">
        <v-text-field
            :autofocus="true"
            color="rgb(181, 39, 135)"
            :label="$ml.get('travel.form.clubNumber')" v-model="travel.club" :rules="clubRules"/>
      </v-col>
      <v-col cols="12" v-for="(passenger) in passengers" v-bind:key="passenger.id">
        <v-row>
          <v-col cols="12" style="margin-bottom: -3em;">
            <v-card-title style="color: #d81d86" v-if="passenger.id!==1"><span
                v-text="$ml.get('travel.form.traveler')"/> {{ passenger.id }}
            </v-card-title>
            <v-card-title style="color: #d81d86" v-else v-text="$ml.get('travel.form.principalTraveler')"/>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
                @change="checkPassengers"
                @keypress="checkPassengers"
                @keydown="checkPassengers"
                v-model="passenger.name"
                :rules="nameRules"
                color="rgb(181, 39, 135)"
                :label="$ml.get('travel.form.inputName')"/>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
                @change="checkPassengers"
                @keypress="checkPassengers"
                @keydown="checkPassengers"
                v-model="passenger.nif"
                v-if="passenger.id===1"
                :rules="nifRules"
                color="rgb(181, 39, 135)"
                :label="$ml.get('travel.form.inputNIF')"/>

            <v-text-field
                @change="checkPassengers"
                @keypress="checkPassengers"
                @keydown="checkPassengers"
                v-model="passenger.nif"
                v-else
                color="rgb(181, 39, 135)"
                :label="$ml.get('travel.form.inputOptionalNIF')"/>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
                @change="checkPassengers"
                @keypress="checkPassengers"
                @keydown="checkPassengers"
                v-model="passenger.phone"
                v-if="passenger.id===1"
                :rules="phoneRules"
                color="rgb(181, 39, 135)"
                :label="$ml.get('travel.form.inputPhone')"/>
            <v-text-field
                v-else
                @change="checkPassengers"
                @keypress="checkPassengers"
                @keydown="checkPassengers"
                v-model="passenger.phone"
                color="rgb(181, 39, 135)"
                :label="$ml.get('travel.form.inputOptionalPhone')"/>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </section>
</template>

<script>
import TitleSection from "./titleSection";

export default {
  name: "buspassengers",
  components: {TitleSection},
  props: ['step', 'travel', 'passengers', 'isMobile'],
  data() {
    return {
      nameRules: [
        value => !!value || this.$ml.get('travel.form.required'),
        value => (value && value.length >= 3) ||  this.$ml.get('travel.form.minleters'),
      ],
      clubRules: [
        value => !!value ||  this.$ml.get('travel.form.required'),
        value => (value && value.length >= 3) || this.$ml.get('travel.form.minchar'),
      ],
      nifRules: [
        value => !!value ||  this.$ml.get('travel.form.required'),
        value => (value && value.length >= 9) || this.$ml.get('travel.form.invaliddni'),
      ],
      phoneRules: [
        value => !!value || this.$ml.get('travel.form.required'),
        value => (value && value.length >= 9) || this.$ml.get('travel.form.invalidphone'),
      ],
      emailRules: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$ml.get('travel.form.invalidmail')
        }
      ],
    }
  },
  methods: {
    checkPassengers() {
      let ok = true;
      let pos = 1;
      if(this.travel.email === '' || this.travel.email === undefined || this.travel.email.length<6) ok = false;
      else{
        this.passengers.forEach(passenger => {
          if (pos === 1 && (passenger.name === '' || passenger.nif === '' || passenger.phone === '' || passenger.name.length < 2 || passenger.phone.length < 8 || passenger.nif.length < 8))
            ok = false;
          else if (passenger.name === '' || passenger.name.length < 2)
            ok = false;
          pos++;
        })
      }
      if (this.travel.typus === '1')
        this.$emit('checked', (ok && (this.travel.email !== ''&& this.travel.email !== undefined && this.travel.email.length>=6)))
      if (this.travel.typus === '4')
        this.$emit('checked', (ok && (this.travel.email !== ''&& this.travel.email !== undefined && this.travel.email.length>=6)))
    },
  }
}
</script>

<style scoped>

</style>