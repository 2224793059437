<template>
  <v-item-group class="align-start">
    <v-chip class="ma-2" v-text="$ml.get('travel.form.busySeat')" color="rgba(213, 39, 135, 0.2) !important"/>
    <v-chip class="ma-2" v-text="$ml.get('travel.form.freeSeat')"/>
    <v-chip class="ma-2" dark v-text="$ml.get('travel.form.reservatedSeat')" style="font-weight: 700;"
            color="#d81d86"/>
  </v-item-group>
</template>

<script>
export default {
  name: "busLegend"
}
</script>

<style scoped>

</style>