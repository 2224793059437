<template>
  <v-col cols="12" md="10" >
    <h3 class="reservationTitle text-left" v-text="$ml.get('travel.terms')"/>
    <div style="display: flex">
      <v-col cols="12" md="6" v-for="file in files" v-bind:key="file.fileID" >
        <v-card class="mr-5 mb-5" outlined>
          <a :href="env_url+'/pdf/'+file.name" target="_blank" class="pdfLink">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline ">
                  <v-icon color="red" class="mr-3" large>mdi-file-table-outline</v-icon>
                  <b>{{ file.name }}</b>
                </div>
              </v-list-item-content>
            </v-list-item>
          </a>
        </v-card>
      </v-col>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "pdfTerms",
  props:['files']
}
</script>

