<template>
  <v-snackbar
      :timeout="-1"
      :value="true"
      color="white"
      bottom
      right>
    <v-card light>
      <v-card-title v-text="$ml.get('travel.form.restantTime')"/>
      <v-card-text v-if="remaining>=2" style="color: #d81d86;font-size: 16pt;font-weight: bold">
        {{ remaining }} <span v-text="$ml.get('travel.form.minuts')"/>
      </v-card-text>
      <v-card-text v-else style="color: #d81d86;font-size: 16pt;font-weight: bold">
        {{ remaining }} <span v-text="$ml.get('travel.form.minut')"/>
      </v-card-text>
    </v-card>
  </v-snackbar>
</template>

<script>
export default {
  name: "remainingAlert",
  props:['remaining']
}
</script>

<style scoped>

</style>