<template>
  <v-card-text>
    <v-row>
      <v-col cols="2" v-for="busLine in bus.slice().reverse()" v-bind:key="busLine.line"
             :style="'padding-top: '+calculateMargin(busLine.line)">
        <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
          <template #activator="{ on }">
            <div v-on="on" class="d-inline-block">
              <v-chip-group active-class="selectedSeat" :max="selected" :multiple="true">
                <v-chip
                    @click="selectSeat(busseat)"
                    v-bind:disabled="isDisableSeat(busseat)">
                  <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                </v-chip>
              </v-chip-group>
            </div>
          </template>
          <span>{{ getBusSeat(busseat) }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "busMobileComponent",
  data(){
    return{
      screenWidth:null,
      bus:null
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth
    this.$forceUpdate();
    this.getBus();
  },
  props:['selectSeat','isDisableSeat','travel','seats','selected'],
  filters: {
    reverse: function (value) {
      return value.slice().reverse();
    }
  },
  methods: {
    getBus(){
      switch (this.travel.busType) {
        case '1':
          if(this.$ml.current === 'Esp')
            this.bus= this.bus55;
          else
            this.bus= this.bus55CAT;
          break;
        case '2':
          if(this.$ml.current === 'Esp')
            this.bus= this.bus63;
          else
            this.bus = this.bus63CAT;
          break;
        case '3':
          if(this.$ml.current === 'Esp')
            this.bus= this.bus71;
          else
            this.bus = this.bus71CAT;
          break;
      }
    },
    getBusSeatImg(busseat) {
      const base = "/assets/css/images/bus/";
      switch (busseat) {
        case 'puerta':
          return base + 'door.png';
        case 'porta':
          return base + 'door.png';
        case 'conductor':
          return base + 'driver.png';
        case 'ocupado':
          return base + 'busyseat.png';
        default:
          return base + 'seat.png';
      }
    },
    getBusSeat(busseat) {
      let found = false;
      this.seats.forEach(seat => {
        if (parseInt(seat.seatID) == busseat) {
          found = true;
        }
      })
      if (found)
        return 'reservado'
      else
        return busseat
    },
    isReservatedSeat(busseat) {
      if (busseat === 'puerta' || busseat === 'porta' ||busseat === 'conductor' || busseat === 'reservado') return true;
      else {
        let found = false;
        this.seats.forEach(seat => {
          if (parseInt(seat.seatID) === busseat)
            found = true;
        })
        return found
      }
    },
    calculateMargin(line) {
      if(this.screenWidth < 500)

        switch (line) {
        case 1:
          return "0em";
        case 2:
          return "0em";
        case 3:
          return "54.5em";
        case 4:
          return "4em";
        case 5:
          return "0em";
        case 6:
          return "0em";
        case 7:
          return "0em";
        case 8:
          return "62.5em";
        case 9:
          return "4em";
        case 10:
          return "0em";
        case 11:
          return "0em";
        case 12:
          return "0em";
        case 13:
          return "70.25em";
        case 14:
          return "4em";
        case 15:
          return "0em";

      }
      else

        switch (line) {
          case 1:
            return "0em";
          case 2:
            return "0em";
          case 3:
            return "48.5em";
          case 4:
            return "4em";
          case 5:
            return "0em";
          case 6:
            return "0em";
          case 7:
            return "0em";
          case 8:
            return "56.5em";
          case 9:
            return "4em";
          case 10:
            return "0em";
          case 11:
            return "0em";
          case 12:
            return "0em";
          case 13:
            return "64.25em";
          case 14:
            return "4em";
          case 15:
            return "0em";

        }
    },
  }
}
</script>

<style scoped>
.selectedSeat {
  background: #d81d86;
}
</style>