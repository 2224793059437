<template>
  <v-col cols="12" md="10">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" v-if="!loaded">
        <v-skeleton-loader
            type="list-item-two-line"/>
      </v-col>
      <v-col cols="12" md="6" v-if="!loaded">
        <v-skeleton-loader
            type="image"/>
      </v-col>
      <v-col cols="12" md="6" v-if="loaded">
        <head-info :travel="travel" :is-mobile="isMobile()" :quantity="quantity"/>
      </v-col>
      <v-col cols="12" md="6" v-if="loaded">
        <v-img height="340" aspect-ratio="1.9"
               :src="this.image_url+travel.image"/>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import HeadInfo from "./partials/headInfo";
export default {
  name: "header_section",
  components: {HeadInfo},
  props:['loaded','quantity','travel','isMobile']
}
</script>

<style scoped>

</style>