<template>
  <v-card class=" align-center align-content-center" style="text-align: center" max-width="100%">
    <h2 class="travelTitle" v-text="$ml.get('travel.form.title')" />
    <persons-query :items="items" :step="step" @selected="selectedPersons" />
    <v-divider v-if="step >= 1" />
    <section v-if="step >= 1" style="background: #fcfcfc">
      <title-section :title="$ml.get('travel.form.seats')" :step="step" :step-equal="1" :indicator="2" />
      <bus-legend />
      <bus-component ref="busComponent" v-if="!isMobile()" :travel="travel" :seats="seats" :selected="selected"
        :select-seat="selectSeat" :is-disable-seat="isDisableSeat" />
      <bus-mobile-component v-if="isMobile()" :travel="travel" :seats="seats" :selected="selected"
        :select-seat="selectSeat" :is-disable-seat="isDisableSeat" />
    </section>
    <v-divider v-if="step >= 2" />
    <section v-if="step >= 2">
      <title-section :step="step" :step-equal="2" :indicator="3" :title="$ml.get('travel.form.rooms.title')" />
      <room-form :travel="travel" :indiv-available-rooms="indivAvailableRooms" :indiv="indiv" :doble="doble"
        :triple="triple" :doble-available-rooms="dobleAvailableRooms" :triple-available-rooms="tripleAvailableRooms"
        @recalculate="recalculateRooms" />
    </section>
    <v-divider v-if="step >= 3" />
    <hotelpassengers v-if="step >= 3" :step="step" :travel="travel" :passengers="passengers"
      @checked="checkCorrectPassengers" :update-rooms="updateRooms" :restore-room="restoreRoom" :is-mobile="isMobile"
      :available-rooms="availableRooms" />
    <v-divider v-if="step >= 4" />
    <section v-if="step === 4">
      <v-card-text class="justify-start" align="start">
        <v-row justify="start" align-content="start">
          <v-col cols="12" md="6" class="justify-start">
            <h3 style="font-weight: bold;text-align: start">
              Total: {{ total }}€
              <span style="color: gray;font-size: 11pt;margin-left: 1em;" v-text="$ml.get('travel.form.tax')" />
              <pay-hotel-button :passengers="passengers" :selection="selection" :travel="travel" :total="total"
                :indiv="indiv" :doble="doble" :tripe="triple" :available-button="availableButton" :refresh="refresh" />
            </h3>
            <v-checkbox v-model="acceptTerms" color="#d81d86">
              <template v-slot:label>
                <div @click.stop="">
                  <span v-text="$ml.get('travel.form.termsAccept')" />
                  <a :href="env_url + '/pdf/' + termsname" rel="nofollow" target="_blank">
                    <span v-text="$ml.get('travel.form.termsConditions')" />
                  </a>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox v-if="travel.covid_certificate === '1'" v-model="covid" :label="$ml.get('travel.form.covid')"
              color="#d81d86" />
            <v-checkbox v-model="newsletter" :label="$ml.get('travel.form.newsletter')" color="#d81d86" />
          </v-col>
          <v-col cols="12" md="6">
            <p class="kidsAdvise">
              <span v-text="$ml.get('travel.form.kidsAdviseFirstPart')" />
              <a href="tel:973750090">973750090</a>
              <span v-text="$ml.get('travel.form.kidsAdviseSecondPart')" />
              <a href="mailto:kariba@autocarsgamon.com">kariba@autocarsgamon.com</a>
            </p>
          </v-col>
        </v-row>
        <br>
      </v-card-text>
    </section>
    <remaining-alert v-if="remaining != null && remaining <= 2 && remaining >= 0" :remaining="remaining" />
  </v-card>
</template>

<script>

import BusComponent from "./busComponent";
import BusMobileComponent from "./busMobileComponent";
import Vue from "vue";
import PersonsQuery from "../reservationForm/personsQuery";
import BusLegend from "../reservationForm/busLegend";
import TitleSection from "../reservationForm/titleSection";
import RoomForm from "../reservationForm/roomForm";
import Hotelpassengers from "../reservationForm/hotelpassengers";
import PayHotelButton from "../reservationForm/payHotelButton";
import RemainingAlert from "../reservationForm/remainingAlert";

export default {
  name: "hotelFormComp",
  components: {
    RemainingAlert,
    PayHotelButton,
    Hotelpassengers, RoomForm, TitleSection, BusLegend, PersonsQuery, BusMobileComponent, BusComponent
  },
  props: ['travel', 'quantity', 'seats', 'termsname', 'isMobile', 'refresh'],
  data() {
    return {
      step: 0,
      items: [],
      selected: 0,
      selection: [],
      total: 0,
      pendingPersons: 0,
      passengers: [],
      started: null,
      remaining: null,
      indiv: 0,
      oldindiv: 0,
      doble: 0,
      olddoble: 0,
      triple: 0,
      oldtriple: 0,
      indivAvailableRooms: [],
      dobleAvailableRooms: [],
      tripleAvailableRooms: [],
      selectedRooms: [],
      availableRooms: [],
      totalIndivRooms: 0,
      totalDoubleRooms: 0,
      totalTripleRooms: 0,
      pendingPassengers: 0,
      acceptTerms: null,
      newsletter: null,
      covid: null,
      loading: false,
    }
  },
  mounted() {
    this.generateItems()
  },

  methods: {
    selectedPersons(qty) {
      var self = this;
      this.selected = qty;
      this.passengers = [];
      this.selection = [];
      this.step = 0;
      this.pendingPassengers = qty;
      this.total = (parseFloat(this.travel.price) * parseFloat(qty)).toFixed(2);
      for (let i = 1; i <= qty; i++) {
        this.passengers.push({ id: i, name: '', nif: '', phone: '', room: null })
      }
      Vue.nextTick(function () {
        console.log("re-render");
        self.selection = [];
        self.step = (qty > 0);
      });
      this.started = new Date();
      setInterval(function () {
        this.remaining = 10 - (Math.round((((new Date() - this.started) % 86400000) % 3600000) / 60000));
        if (this.remaining === 0) {
          this.step = 0
          this.total = null
          this.started = null
          this.remaining = null
          this.passengers = []
        }
      }.bind(this), 1000);
      this.calculateTotalRooms(this.pendingPassengers);
      this.$ma.trackEvent({
        category: 'Travel',
        action: 'set passengers',
        properties: { version: this.version, quantity: qty }
      });
    },
    generateItems() {
      if (this.travel.typus === '3') {
        if (this.quantity >= 2)
          for (let i = 1; i <= 2; i++)
            this.items.push(i);
        else
          for (let i = 1; i <= 1; i++)
            this.items.push(i);
      }
      else {
        if (this.quantity >= 10)
          for (let i = 1; i <= 10; i++)
            this.items.push(i)
        else
          for (let j = 1; j <= this.quantity; j++)
            this.items.push(j)
      }
    },
    isDisableSeat(busseat) {
      if (this.selection.length === this.selected) {
        let found = false
        this.selection.forEach(seat => {
          if (seat === busseat) found = true
        })
        return !found
      } else {
        if (busseat === 'puerta' || busseat === 'porta' || busseat === 'conductor' || busseat === 'No disponible') return true;
        else {
          let found = false;
          this.seats.forEach(seat => {
            if (parseInt(seat.seatID) === busseat)
              found = true;
          })
          return found
        }
      }
    },
    selectSeat(item) {
      let pos = 0;
      let found = false;
      this.selection.forEach(seat => {
        if (seat === item) {
          found = true;
          this.selection.splice(pos, 1);
        }
        pos++;
      })
      if (!found && this.selection.length !== this.selected) this.selection.push(item)
      if (this.selection.length === this.selected) this.step = 2;
      else this.step = 1;
      this.$ma.trackEvent({
        category: 'Travel',
        action: 'select seat',
        properties: { version: this.version, selection: this.selection }
      });
    },
    calculateTotalRooms(passengers) {
      this.totalIndivRooms = 0;
      this.totalDoubleRooms = 0;
      this.totalTripleRooms = 0;
      this.indivAvailableRooms = [];
      this.dobleAvailableRooms = [];
      this.tripleAvailableRooms = [];
      this.totalIndivRooms = passengers;
      this.dobleAvailableRooms.push(0);
      this.tripleAvailableRooms.push(0);
      for (let i = 0; i <= passengers; i++)
        this.indivAvailableRooms.push(i)
      for (let j = 1; j <= passengers; j++) {
        if (j % 2 === 0) {
          this.totalDoubleRooms++;
          this.dobleAvailableRooms.push(this.dobleAvailableRooms.length)
        }
      }
      for (let k = 1; k <= passengers; k++) {
        if (k % 3 === 0) {
          this.totalTripleRooms++;
          this.tripleAvailableRooms.push(this.tripleAvailableRooms.length)
        }
      }
    },
    recalculateRooms(qty, type) {
      
      if (type === 1) {
        if (qty < this.indiv) {
          this.total = parseFloat(this.total) - parseFloat((this.indiv - qty) * this.travel.singleRoomPlus)
          this.total = this.total.toFixed(2)
        } else if (qty > this.indiv) {
          this.total = parseFloat(this.total) + parseFloat(qty * this.travel.singleRoomPlus)
          this.total = this.total.toFixed(2)
        }
        this.indiv = qty;
      }
      if (type === 2) this.doble = qty;
      if (type === 3) this.triple = qty;
      this.indivAvailableRooms = [];
      this.dobleAvailableRooms = [];
      this.tripleAvailableRooms = [];
      this.selectedRooms = [];
      this.dobleAvailableRooms.push(0);
      this.tripleAvailableRooms.push(0);
      if (type === 1)
        if (qty >= this.oldindiv) {
          this.pendingPassengers = this.pendingPassengers - (qty - this.oldindiv);
          this.oldindiv = qty;
        } else {
          this.pendingPassengers = this.pendingPassengers + (this.oldindiv - qty);
          this.oldindiv = qty;
        }

      if (type === 2)
        if (qty >= this.olddoble) {
          this.pendingPassengers = this.pendingPassengers - ((qty - this.olddoble) * 2);
          this.olddoble = qty;
        } else {
          this.pendingPassengers = this.pendingPassengers + ((qty - this.olddoble) * -2);
          this.olddoble = qty;
        }

      if (type === 3)
        if (qty >= this.oldtriple) {
          this.pendingPassengers = this.pendingPassengers - ((qty - this.oldtriple) * 3);
          this.oldtriple = qty
        } else {
          this.pendingPassengers = this.pendingPassengers + ((this.oldtriple - qty) * 3);
          this.oldtriple = qty
        }

      if (type === 1) {
        if (this.doble === 0 && this.triple === 0)
          for (let i = 0; i <= this.selected; i++)
            this.indivAvailableRooms.push(i)
        else if (this.indiv !== 0)
          for (let i = 0; i <= this.indiv; i++)
            this.indivAvailableRooms.push(i)
        else
          for (let i = 0; i <= this.pendingPassengers; i++)
            this.indivAvailableRooms.push(i)
        if (this.doble === 0)
          for (let j = 1; j <= this.pendingPassengers; j++) {
            if (j % 2 === 0)
              this.dobleAvailableRooms.push(this.dobleAvailableRooms.length)
          }
        else
          for (let j = 1; j <= (this.pendingPassengers + this.doble * 2); j++)
            if (j % 2 === 0)
              this.dobleAvailableRooms.push(this.dobleAvailableRooms.length)
        if (this.triple === 0)
          for (let k = 1; k <= this.pendingPassengers; k++) {
            if (k % 3 === 0)
              this.tripleAvailableRooms.push(this.tripleAvailableRooms.length)
          }
        else
          for (let k = 1; k <= (this.pendingPassengers + this.triple * 3); k++)
            if (k % 3 === 0)
              this.tripleAvailableRooms.push(this.tripleAvailableRooms.length)
      }
      if (type === 2) {
        if (this.indiv === 0 && this.triple === 0)
          for (let i = 1; i <= this.selected; i++) {
            if (i % 2 === 0)
              this.dobleAvailableRooms.push(this.dobleAvailableRooms.length)
          }
        else if (this.doble !== 0)
          for (let i = 0; i <= this.doble; i++)
            this.dobleAvailableRooms.push(i)
        else
          for (let j = 1; j <= this.pendingPassengers; j++)
            if (j % 2 === 0)
              this.dobleAvailableRooms.push(this.dobleAvailableRooms.length)
        if (this.indiv === 0)
          for (let i = 0; i <= this.pendingPassengers; i++)
            this.indivAvailableRooms.push(i)
        else
          for (let i = 0; i <= (this.pendingPassengers + this.indiv); i++)
            this.indivAvailableRooms.push(i)
        if (this.triple === 0)
          for (let k = 1; k <= this.pendingPassengers; k++) {
            if (k % 3 === 0)
              this.tripleAvailableRooms.push(this.tripleAvailableRooms.length)
          }
        else
          for (let k = 1; k <= (this.pendingPassengers + this.triple * 3); k++)
            if (k % 3 === 0)
              this.tripleAvailableRooms.push(this.tripleAvailableRooms.length)
      }
      if (type === 3) {
        if (this.indiv === 0 && this.doble === 0)
          for (let i = 1; i <= this.selected; i++) {
            if (i % 3 === 0)
              this.tripleAvailableRooms.push(this.tripleAvailableRooms.length)
          }
        else if (this.triple !== 0)
          for (let i = 0; i <= this.triple; i++)
            this.tripleAvailableRooms.push(i)
        else
          for (let k = 1; k <= this.pendingPassengers; k++)
            if (k % 3 === 0)
              this.tripleAvailableRooms.push(this.tripleAvailableRooms.length)
        if (this.doble === 0)
          for (let j = 1; j <= this.pendingPassengers; j++) {
            if (j % 2 === 0)
              this.dobleAvailableRooms.push(this.dobleAvailableRooms.length)
          }
        else
          for (let j = 1; j <= (this.pendingPassengers + this.doble * 2); j++)
            if (j % 2 === 0)
              this.dobleAvailableRooms.push(this.dobleAvailableRooms.length)
        if (this.indiv === 0)
          for (let i = 0; i <= this.pendingPassengers; i++)
            this.indivAvailableRooms.push(i)
        else
          for (let i = 0; i <= (this.pendingPassengers + this.indiv); i++)
            this.indivAvailableRooms.push(i)
      }

      if (this.pendingPassengers === 0) {
        for (let i = 1; i <= this.oldindiv; i++)
          this.selectedRooms.push({ id: i, name: 'Habitación individual ' + i, capacity: 1 })
        for (let i = 1; i <= this.olddoble; i++)
          this.selectedRooms.push({ id: i, name: 'Habitación doble ' + i, capacity: 2 })
        for (let i = 1; i <= this.oldtriple; i++)
          this.selectedRooms.push({ id: i, name: 'Habitación triple ' + i, capacity: 3 })
        this.step = 3;
        this.availableRooms = this.selectedRooms;
      } else this.step = 2;
    },
    checkCorrectPassengers(checked) {
      if (checked) {
        this.step = 4;
        this.$ma.setAlias(this.travel.email);
        this.$ma.setUsername(this.travel.email);
        this.$ma.setUserProperties({ name: this.passenger[0].name });
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'check passengers info',
          properties: { version: this.version, isOK: checked }
        });
      } else this.step = 3;
    },
    checkPassengers() {
      let ok = true;
      let pos = 1;
      this.passengers.forEach(passenger => {
        if (pos === 1 && (passenger.name === '' || passenger.nif === '' || passenger.phone === '' || passenger.room == null))
          ok = false;
        else if (passenger.name === '' || passenger.nif === '' || passenger.nif.length < 8 || passenger.room == null)
          ok = false;
        pos++;
      })
      this.$emit('checked', (ok && this.travel.email !== ''))
    },
    updateRooms(room) {
      this.availableRooms.map(aRoom => {
        if (aRoom.name === room) {
          aRoom.capacity = aRoom.capacity - 1
        }
      })
      let newAvailableRooms = [];
      this.selectedRooms.forEach(sRoom => {
        this.availableRooms.forEach(aRoom => {
          if (sRoom.name === aRoom.name)
            if (aRoom.capacity > 0)
              newAvailableRooms.push(sRoom)
        })
      })
      this.availableRooms = newAvailableRooms
      this.$ma.trackEvent({
        category: 'Travel',
        action: 'updateRooms',
        properties: { version: this.version, availableRooms: this.availableRooms, selectedRoom: room }
      });
      this.checkPassengers();
    },
    restoreRoom(room, index) {
      this.selectedRooms.map(aRoom => {
        if (aRoom.name === room) {
          aRoom.capacity = aRoom.capacity + 1
        }
      })
      let newAvailableRooms = [];
      this.selectedRooms.forEach(sRoom => {
        let found = false;
        this.availableRooms.forEach(aRoom => {
          if (sRoom.name === aRoom.name) {
            found = true;
            if (aRoom.capacity > 0)
              newAvailableRooms.push(sRoom)
          }
        });
        if (!found) if (sRoom.capacity > 0)
          newAvailableRooms.push(sRoom)
      })
      this.availableRooms = newAvailableRooms;
      this.passengers[index].room = null;
      room = null;
      this.checkPassengers();
    },
    availableButton() {
      if (this.travel.covid_certificate === '1')
        return (this.acceptTerms && this.covid)
      else return this.acceptTerms
    }
  }
}
</script>

<style>
section {
  margin-bottom: 0 !important;

}



.roomTitle {
  font-size: 12pt;
}

.roomSubtitle {
  font-weight: 700;
  display: block;
}

.kidsAdvise {
  margin-top: 1em;
  border: 1px solid lightgray;
  padding: 1em;
  font-size: 14pt;
}

.v-slide-group__content {
  display: block !important;
}

.selectedSeat {
  background: #d81d86;
}

.restoreIcon :hover {
  color: #d81d86;
}

.v-select__slot .v-label.theme--light {
  font-size: 13.25pt;
}

.v-divider {
  border-width: 3px 1px 0 0;
}

@media (min-width: 500px) {
  .roomCol {
    display: block ruby;
    margin: 0 auto;
  }

  .v-select__slot .v-label.theme--light {
    font-size: 11pt;
  }
}
</style>