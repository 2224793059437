<template>
  <v-col cols="12" md="11" class="infoTravel" id="info" v-html="desc" />
</template>

<script>
export default {
  name: "travelInfo",
  props:['desc']
}
</script>

<style >
.infoTravel {
  text-align: justify;
}

.infoTravel h3 {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.2;
  color: black;
}

.infoTravel h4 {
  font-size: 90%;
  font-style: inherit;
  font-weight: bold;
  margin-bottom: 20px;
  color: black !important;
}

.infoTravel p {
  line-height: 1.5;
  margin: 0 0 1.75em;
}
.infoTravel a{
  color:#d81d86;
}
.infoTravel b{
  font-weight: 900;
}
.infoTravel strong{
  font-weight: bold;
}
</style>