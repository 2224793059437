<template>
  <v-card-text style="padding: -1em">
    <div style="display: block; margin: 0 auto;">
      <div class="line1" v-for="busLine in bus" v-bind:key="busLine.line">
        <div class="seats2" :style="'margin-left: '+calculateMargin(busLine.line)">
          <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
            <template #activator="{ on }">
              <div v-on="on" class="d-inline-block">
                <v-chip-group :mandatory="false" active-class="selectedSeat" :max="selected" :multiple="true">
                  <v-chip
                      @click="selectSeat(busseat)"
                      v-bind:class="{available:!isReservatedSeat(busseat)}"
                      v-bind:disabled="isDisableSeat(busseat)">
                    <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
            <span>{{ getBusSeat(busseat) }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "busComponent",
  props:['selectSeat','isDisableSeat','travel','seats','selected'],
  data(){
    return{
      screenWidth:null,
      bus:null
    }
  },
  methods:{
    getBus(){
      switch (this.travel.busType) {
        case '1':
          if(this.$ml.current === 'Esp')
            this.bus= this.bus55;
          else
            this.bus= this.bus55CAT;
          break;
        case '2':
          if(this.$ml.current === 'Esp')
            this.bus= this.bus63;
          else
            this.bus = this.bus63CAT;
          break;
        case '3':
          if(this.$ml.current === 'Esp')
            this.bus= this.bus71;
          else
            this.bus = this.bus71CAT;
          break;
      }
    },
    getBusSeatImg(busseat) {
      const base = "/assets/css/images/bus/";
      switch (busseat) {
        case 'puerta':
          return base + 'door.png';
        case 'porta':
          return base + 'door.png';
        case 'conductor':
          return base + 'driver.png';
        case 'Non disponible':
          return base + 'busyseat.png';
        default:
          return base + 'seat.png';
      }
    },
    getBusSeat(busseat) {
      let found = false;
      this.seats.forEach(seat => {
        if (parseInt(seat.seatID) == busseat) {
          found = true;
        }
      })
      if (found)
        return 'No disponible'
      else
        return busseat
    },
    isReservatedSeat(busseat) {
      if (busseat === "porta") return true;
      if (busseat === 'puerta' || busseat === 'conductor' || busseat === 'No disponible') return true;
      else {
        let found = false;
        this.seats.forEach(seat => {
          if (parseInt(seat.seatID) === busseat)
            found = true;
        })
        return found
      }
    },
    calculateMargin(line) {
      switch (line) {
        case 1:
          return "3.75em";
        case 2:
          return "3.75em";
        case 3:
          return "56em";
        case 4:
          return "7.75em";
        case 5:
          return "4em";
        case 6:
          return "3.75em";
        case 7:
          return "3.75em";
        case 8:
          return "63.25em";
        case 9:
          return "7.75em";
        case 10:
          return "4em";
        case 11:
          return "2.75em";
        case 12:
          return "2.75em";
        case 13:
          return "69.5em";
        case 14:
          return "6.75em";
        case 15:
          return "3em";
      }
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth
    this.$forceUpdate();
    this.getBus();
  },
}
</script>

<style scoped>
.selectedSeat {
  background: #d81d86;
}
</style>
<style>
.v-chip--disabled {
  background: rgba(213, 39, 135, 0.2) !important;
  opacity: 0.8 !important;
}

.v-chip--disabled.available {
  background: #e5e5e5 !important;
}
</style>