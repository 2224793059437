<template>
  <v-card class=" align-center align-content-center" style="text-align: center" max-width="100%">
    <h2 class="travelTitle" v-text="$ml.get('travel.form.title')"/>
    <persons-query :items="items" :step="step" @selected="selectedPersons"/>
    <v-divider v-if="step>=1" class="mx-4"/>
    <section v-if="step>=1" style="background: #fcfcfc">
      <title-section :title="$ml.get('travel.form.seats')" :step="step" :step-equal="1" :indicator="2"/>
      <bus-legend/>
      <bus-component ref="busComponent" v-if="!isMobile()" :travel="travel" :seats="seats" :selected="selected"
                     :select-seat="selectSeat" :is-disable-seat="isDisableSeat"/>
      <bus-mobile-component v-if=" isMobile()" :travel="travel" :seats="seats" :selected="selected"
                            :select-seat="selectSeat" :is-disable-seat="isDisableSeat"/>
    </section>
    <v-divider class="mx-4" v-if="step>=2"/>
    <buspassengers v-if="step>=2" :step="step" :travel="travel" :passengers="passengers" @checked="checkPassengers"
                   :is-mobile="isMobile()"/>
    <v-divider class="mx-4" v-if="step===3"></v-divider>
    <v-card-text v-if="step===3" class="justify-start" align="start">
      <v-row justify="start" align-content="start">
        <v-col cols="12" md="6" class="justify-start">
          <h3 style="font-weight: bold;text-align: start">
            Total: {{ total }}€
            <span style="color: gray;font-size: 11pt;margin-left: 1em;" v-text="$ml.get('travel.form.tax')"/>
            <pay-bus-button :passengers="passengers" :selection="selection" :travel="travel" :total="total"
                        :available-button="availableButton" :refresh="refresh"/>
          </h3>
          <v-checkbox
              ref="acceptedRef"
              v-model="acceptTerms"
              color="#d81d86">
            <template v-slot:label>
              <div @click.stop="">
                <span v-text="$ml.get('travel.form.termsAccept')"/>
                <a :href="env_url+'/pdf/'+termsname" rel="nofollow" target="_blank">
                  <span v-text="$ml.get('travel.form.termsConditions')"/>
                </a>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
              v-if="travel.covid_certificate==='1'"
              v-model="covid"
              :label="$ml.get('travel.form.covid')"
              color="#d81d86"/>
          <v-checkbox
              v-model="newsletter"
              :label="$ml.get('travel.form.newsletter')"
              color="#d81d86"/>
        </v-col>
        <v-col cols="12" md="6">
          <p class="kidsAdvise">
            <span v-text="$ml.get('travel.form.kidsAdviseFirstPart')"/>
            <a href="tel:973750090">973750090</a>
            <span v-text="$ml.get('travel.form.kidsAdviseSecondPart')"/>
            <a href="mailto:kariba@autocarsgamon.com">kariba@autocarsgamon.com</a>
          </p>
        </v-col>
      </v-row>
      <br>
    </v-card-text>
    <remaining-alert v-if="remaining != null && remaining <=2 && remaining>=0" :remaining="remaining"/>
  </v-card>
</template>

<script>
import BusComponent from "./busComponent";
import BusMobileComponent from "./busMobileComponent";
import Vue from "vue";
import PersonsQuery from "../reservationForm/personsQuery";
import BusLegend from "../reservationForm/busLegend";
import Buspassengers from "../reservationForm/buspassengers";
import PayBusButton from "../reservationForm/payBusButton";
import TitleSection from "../reservationForm/titleSection";
import RemainingAlert from "../reservationForm/remainingAlert";
export default {
  name: "busFormComp",
  components: {
    RemainingAlert,
    TitleSection, PayBusButton, Buspassengers, BusLegend, PersonsQuery, BusMobileComponent, BusComponent},
  props: ['travel', 'quantity', 'seats', 'termsname', 'isMobile','refresh'],
  data() {
    return {
      screenWidth: null,
      items: [],
      persons: [],
      passengers: [],
      selection: [],
      selected: null,
      total: null,
      step: 0,
      started: null,
      remaining: null,
      acceptTerms: null,
      newsletter: null,
      covid: null,
      loading: false,
    }
  },
  mounted() {
    this.generateItems();
    this.screenWidth = window.innerWidth
  },
  methods: {
    selectedPersons(qty) {
      var self = this;
      this.selected = qty;
      this.step = 0;
      this.total = (parseFloat(this.travel.price) * parseFloat(qty)).toFixed(2);
      this.persons = [];
      this.passengers = [];
      for (let i = 0; i < qty; i++) {
        let item = 'Persona ' + (i + 1);
        this.persons.push(item)
        this.passengers.push({id: i + 1, name: '', nif: '', phone: ''})
      }
      Vue.nextTick(function () {
        self.selection = [];
        self.step = (qty > 0);
      })
      this.started = new Date();
      setInterval(function () {
        this.remaining = 10 - (Math.round((((new Date() - this.started) % 86400000) % 3600000) / 60000));
        if (this.remaining === 0) {
          this.step = 0
          this.total = null
          this.started = null
          this.remaining = null
          this.persons = []
          this.passengers = []
        }
      }.bind(this), 1000);
      this.$ma.trackEvent({
        category: 'Travel',
        action: 'set passengers',
        properties: {version: this.version, quantity: qty}
      });
    },
    generateItems() {
      if(this.travel.typus==='4'){
        if (this.quantity >= 2)
          for (let i = 1; i <= 2; i++)
            this.items.push(i);
        else
          for (let i = 1; i <= 1; i++)
            this.items.push(i);
      }
      else{
        if (this.quantity >= 10)
          for (let i = 1; i <= 10; i++)
            this.items.push(i)
        else
          for (let j = 1; j <= this.quantity; j++)
            this.items.push(j)
      }
    },
    isDisableSeat(busseat) {
      if (this.selection.length === this.selected) {
        let found = false
        this.selection.forEach(seat => {
          if (seat === busseat) found = true
        })
        return !found
      } else {
        if (busseat === 'puerta' || busseat === 'porta' ||busseat === 'conductor' || busseat === 'No disponible') return true;
        else {
          let found = false;
          this.seats.forEach(seat => {
            if (parseInt(seat.seatID) === busseat)
              found = true;
          })
          return found
        }
      }
    },
    selectSeat(item) {
      let pos = 0;
      let found = false;
      this.selection.forEach(seat => {
        if (seat === item) {
          found = true;
          this.selection.splice(pos, 1);
        }
        pos++;
      })
      if (!found && this.selection.length !== this.selected) this.selection.push(item)
      if (this.selection.length === this.selected) this.step = 2;
      else this.step = 1;
      this.$ma.trackEvent({
        category: 'Travel',
        action: 'select seat',
        properties: {version: this.version, selection: this.selection}
      });
    },
    checkPassengers(checked) {
      if (checked) {
        this.step = 3;
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'check passengers info',
          properties: {version: this.version, isOK: checked}
        });
        this.$ma.setAlias(this.travel.email);
        this.$ma.setUsername(this.travel.email);
        this.$ma.setUserProperties({name: this.passenger[0].name});
      }
      else this.step = 2;

    },
    availableButton() {
      if (this.travel.covid_certificate === '1')
        return (this.acceptTerms && this.covid)
      else return this.acceptTerms
    }
  }
}
</script>

<style scoped>
section {
  margin-bottom: 0 !important;

}

.titleSection {
  color: #d81d86;
  word-break: break-word !important;
}

.kidsAdvise {
  margin-top: 1em;
  border: 1px solid lightgray;
  padding: 1em;
  font-size: 14pt;
}

.v-slide-group__content {
  display: block !important;
}

.selectedSeat {
  background: #d81d86;
}
</style>
<style>
.v-chip--disabled {
  background: rgba(213, 39, 135, 0.2) !important;
}

.ma-2.justify-start.payBT.v-btn.v-btn--disabled.v-btn--has-bg.theme--dark.v-size--default {
  background: lightgray !important;
  color: white !important;
}
</style>